// Product.js
import React from "react";
import { Link } from "react-router-dom";
import "./ProductGrid.css";
import { Carousel } from "react-bootstrap";



const Product = ({ image, title, description, code, link }) => {
  return (
    <div className="product-general-general">
      {image && image.length > 0 ? (
        <Carousel>
          {image.map((image, index) => (
            <Carousel.Item key={index}>
              <img className="d-block w-100" src={image} alt={`Slide ${index}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <p>No hay imágenes disponibles</p>
      )}
      <div className="card-general-general">
        <h3>{title}</h3>
        <p>{description}</p>
        <p className="product-general-code-general">Código: {code}</p>
        <Link to={link} className="more-info-button-general">
          Consultar
        </Link>
      </div>
    </div>
  );
};

export default Product;