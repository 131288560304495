// Product.js
import React from "react";
import { Link } from "react-router-dom";
import "./ProductFiltro.css";


const ProductFiltro = ({ image, title, link }) => {
  return (
    <div className="product-filtro-card">
      <img src={image} alt={title} />
      <div className="card-filtro-content">
        <h3>{title}</h3>
       
      </div>
    </div>
  );
};

export default ProductFiltro;
