/* eslint-disable */
import React from "react";
import image from "../images/zfcar.jpeg"

const ProductosCarraro = () => {
  return (
    <div className="img-zf-car">
      <img src={image} ></img>
    </div>
  );
};

export default ProductosCarraro;
