import React, { useState } from "react";
import axios from "axios";
import "./forms.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://api.tractoagro.com.ar/api/forgot-password", {
        email,
      });
      setSuccessMessage("Enlace enviado a su email.");
      setErrorMessage(""); // Limpiar mensaje de error si el éxito ocurre
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage("El correo electrónico no está registrado.");
      } else {
        setErrorMessage(
          "Hubo un problema al enviar el correo. Intenta nuevamente."
        );
      }
      setSuccessMessage(""); // Limpiar mensaje de éxito si ocurre un error
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Cambiar Contraseña</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Ingresá tu Email registrado"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Restablecer Contraseña</button>
      </form>
      {successMessage && (
        <div className="popup-container">
          <div className="popup success-popup">{successMessage}</div>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ForgotPassword;
