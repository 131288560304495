// Product2.js
import React from "react";
import "../DetallesProductos.css";
import ImageGallery from "./ImageGallery";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import image1 from "../../images/productos/ejes/101565/a.jpg";
import image2 from "../../images/productos/ejes/101565/b.jpg";
import image3 from "../../images/productos/ejes/101565/c.jpg";
import image4 from "../../images/productos/ejes/101565/d.jpg";
import image6 from "../../images/banerchico.png";

const Product4 = () => {
  const images = [image1, image2, image3, image4]; // Agrega más URLs de imágenes según sea necesario

  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={image6} alt="Primera imagen" />
        </Carousel.Item>

        {/* Agrega más items según sea necesario */}
      </Carousel>
      <a href="/" rel="noopener noreferrer" className="buy-button3">
        Volver
      </a>
      <div className="detalles-container">
        <ImageGallery images={images} />
        <div className="details-content">
          <h2>Buje</h2>
          <h3>Eje delantero - Carraro</h3>
          <p>
            Marcas/Aplicaciones: Pauny - Modelos: EVO 230A - 250A - 280A /
            Fiat-NH RETROEXCAVADORA B90B{" "}
          </p>

          <p className="product-code">
            <strong>Código:</strong> 101565
          </p>
          <a
            href="https://wa.me/5493412170349?text=Estoy%20interesado%20en%20este%20producto:%20Buje%20cod:%101565..."
            target="_blank"
            rel="noopener noreferrer"
            className="buy-button"
          >
            Comprar
          </a>
        </div>
      </div>
    </div>
  );
};

export default Product4;
