import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaEnvelope,
} from "react-icons/fa";
import "./Contacto.css";


const Contacto = () => {
  return (
    <div className="contacto-container">
      <h2 className="medios">Nuestros medios de contacto</h2>
      <div className="redes-sociales">
        <div className="red-social">
          <FaFacebook size={32} />
          <a
            href="https://www.facebook.com/Tractoagro"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        </div>
        <div className="red-social">
          <FaInstagram size={32} />
          <a
            href="https://www.instagram.com/tractoagro.repuestos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </div>
        <div className="red-social">
          <FaLinkedin size={32} />
          <a
            href="https://www.linkedin.com/company/tractoagro/mycompany/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </div>
        <div className="red-social">
          <FaWhatsapp size={32} />
          <a
            href="https://wa.me/+5493412170349"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contactanos por WhatsApp
          </a>
        </div>
        <div className="red-social">
          <FaEnvelope size={32} />
          <a
            href="mailto:ventas@tractoagro.com.ar"
            target="_blank"
            rel="noopener noreferrer"
          >
            Envianos un Email
          </a>
        </div>
      </div>
      <div className="direccion">
        <p className="ubicacion">
          Ubicación: Paunero 3154, Ciudad de Rosario, Provincia de Santa Fe
        </p>
        {/* Aquí puedes insertar tu mapa de Google */}
        <iframe
          title="Google Maps"
          width="100%"
          height="300"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d837.6211132406039!2d-60.7262089!3d-32.8853574!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b6524a49137303%3A0xbde48155d99282a4!2sTractoagro%20-%20Repuestos%20de%20Tractores%20y%20Cosechadoras!5e0!3m2!1ses-419!2sar!4v1705950963666!5m2!1ses-419!2sar"
        ></iframe>
      </div>
    </div>
  );
};

export default Contacto;
