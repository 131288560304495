import React, { useState, useEffect } from "react";
import Product from "../components/ProductGral";
import WhatsAppButton from "./WhatsAppButton";
import "./ProductGrid.css";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import imagebanner from "../images/banFPM.jpg";
import image from "../images/productos/fpm/29.png";
import image1 from "../images/productos/fpm/29b.png";
import image2 from "../images/productos/fpm/158.png";
import image3 from "../images/productos/fpm/158b.png";
import image4 from "../images/productos/fpm/166.png";
import image5 from "../images/productos/fpm/166b.png";
import image6 from "../images/productos/fpm/308.png";
import image7 from "../images/productos/fpm/308b.png";
import image8 from "../images/productos/fpm/343.png";
import image9 from "../images/productos/fpm/343b.png";
import image10 from "../images/productos/fpm/3150.png";
import image11 from "../images/productos/fpm/3150b.png";
import image12 from "../images/productos/fpm/3965.png";
import image13 from "../images/productos/fpm/3965b.png";
import image14 from "../images/productos/fpm/4214.png";
import image15 from "../images/productos/fpm/4214b.png";
import image16 from "../images/productos/fpm/4921.png";
import image17 from "../images/productos/fpm/4921b.png";
import image18 from "../images/productos/fpm/4921c.png";
import image19 from "../images/productos/fpm/4921d.png";
import image20 from "../images/productos/fpm/5463.png";
import image21 from "../images/productos/fpm/5463b.png";
import Loader from "./loader";


const ProductFpm = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulación de carga de la página
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
    // Cambia este tiempo según tus necesidades
  }, []);
  const [productsWithDefaultImage] = useState([
    {
      id: 1,
      image: [image, image1],
      title: "Placa armada M.F. (FPM)",
      description: "Embragues / Massey Ferguson  Equivalencia: 1868005M93",
      code: "29",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 2,
      image: [image2, image3],
      title: "Embrague completo (FPM)",
      description: "Embragues / completo con 2 discos  Equivalencia: 44700T1",
      code: "158",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 3,
      image: [image4, image5],
      title: "Embrague diafragma (FPM)",
      description:
        "Embragues / a diafragma 13 y 1/2 (340mm) / Equivalencia: 026566T3",
      code: "166",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 4,
      image: [image6, image7],
      title: "Manguito eje mando (FPM)",
      description:
        "Embragues / Massey Ferguson 9007628 / Equivalencia: 6272663M91 ",
      code: "308",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 5,
      image: [image8, image9],
      title: "Yugo horquilla (FPM)",
      description: "Embragues / Massey Ferguson 4804888 Equivalencia: 3409609",
      code: "343",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 6,
      image: [image10, image11],
      title: "Disco 12´´copa forjada (FPM)",
      description: "Embragues MF / Marca Deutz    Equivalencia: 3020626",
      code: "3150",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 7,
      image: [image12, image13],
      title: "Rotula axial MF (FPM)",
      description: "Embragues / Rotula DQ28534 / Equivalencia: 61267R1 ",
      code: "3965",
      link: "https://wa.me/+5493412170349",
    },
    {
      id: 8,
      image: [image14, image15],
      title: "Barra completa NH (FPM)",
      description:
        "Embragues / Barra 062330 R1 - 6220966 M1 / Equivalencia: 84328362",
      code: "4214",
      link: "https://wa.me/+5493412170349",
    },
    {
      id: 9,
      image: [image16, image17, image18, image19],
      title: "Disco interno 5189825 (FPM)",
      description:
        "Embragues / Disco cerametalico JD / Equivalencia: AJ56772AJ61808  ",
      code: "4921",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 10,
      image: [image20, image21],
      title: "Disco ceramico 15´´ (FPM)",
      description:
        "Embragues / Disco rigido ceramico / Alternativo a cod: 2868",
      code: "5463",
      link: "https://wa.me/5493412170349",
    },

    // Agrega más productos según sea necesario
  ]);

  return (
    <div>
      {isLoading ? (
        <Loader /> // Muestra el loader mientras isLoading es true
      ) : (
        <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={imagebanner}
            alt="Primera imagen"
          />
        </Carousel.Item>

        {/* Agrega más items según sea necesario */}
      </Carousel>
      <h2 className="pgh2">Nuestros Productos</h2>
      <a
        href="/productsFiltro"
        rel="noopener noreferrer"
        className="buy-button2"
      >
        Volver
      </a>
      <div className="product-general-generalcontainer">
        {productsWithDefaultImage.map((product) => (
          <Product
            key={product.id}
            image={product.image}
            title={product.title}
            description={product.description}
            code={product.code}
            link={product.link}
          />
        ))}
      </div>
      <div className="body-home">
        {/* ... (resto del código) */}
        {/* Agrega el botón de WhatsApp al final */}
        <WhatsAppButton />
      </div>
      </>
      )}
    </div>
  );
};

export default ProductFpm;
