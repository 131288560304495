import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  // Puedes agregar enlaces o acciones específicas al hacer clic en el logo
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/+5493412170349", "_blank");
  };

  return (
    (
      <div className="whatsapp-button-container" onClick={handleWhatsAppClick}>
        <FaWhatsapp className="whatsapp-icon" />
      </div>
    )
  );
};

export default WhatsAppButton;
