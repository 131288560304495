import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Product from "../components/ProductGral";
import WhatsAppButton from "./WhatsAppButton";
import "./ProductGrid.css";
import Loader from "./loader";
import imageCarrusel from "../images/banercarzf.jpeg";
import baner1 from "../images/banCARZF.jpg";
import baner2 from "../images/banCAR.jpg";
import baner3 from "../images/banZF.jpg";
import image from "../images/productos/ejes/40971/a.jpg";
import image1 from "../images/productos/ejes/40971/b.jpg";
import image2 from "../images/productos/ejes/40971/c.jpg";
import image3 from "../images/productos/ejes/40971/d.jpg";
import image4 from "../images/productos/ejes/66294/a.jpg";
import image5 from "../images/productos/ejes/66294/b.jpg";
import image6 from "../images/productos/ejes/66294/c.jpg";
import image7 from "../images/productos/ejes/66294/d.jpg";
import image8 from "../images/productos/ejes/101565/a.jpg";
import image9 from "../images/productos/ejes/101565/b.jpg";
import image10 from "../images/productos/ejes/101565/c.jpg";
import image11 from "../images/productos/ejes/101565/d.jpg";
import image12 from "../images/productos/ejes/95353232/a.jpg";
import image13 from "../images/productos/ejes/95353232/b.jpg";
import image14 from "../images/productos/ejes/95353232/c.jpg";
import image15 from "../images/productos/ejes/95353232/d.jpg";
import image16 from "../images/productos/ejes/734309422/a.jpg";
import image17 from "../images/productos/ejes/734309422/b.jpg";
import image18 from "../images/productos/ejes/734309422/c.jpg";
import image19 from "../images/productos/ejes/734309422/d.jpg";
import image20 from "../images/productos/ejes/570618/a.png";
import image21 from "../images/productos/ejes/570618/b.png";
import image22 from "../images/productos/ejes/570618/c.png";
import image23 from "../images/productos/ejes/4472353157/a.jpg";
import image24 from "../images/productos/ejes/4472353157/b.jpg";
import image25 from "../images/productos/ejes/4472353157/c.jpg";
import image26 from "../images/productos/ejes/4472353157/d.jpg";
import image27 from "../images/productos/ejes/4472353157/e.jpg";
import image28 from "../images/productos/ejes/6285917/a.jpg";
import image29 from "../images/productos/ejes/6285917/b.jpg";
import image30 from "../images/productos/ejes/123475/a.jpg";
import image31 from "../images/productos/ejes/123475/b.jpg";
import image32 from "../images/productos/ejes/123475/c.jpg";
import image33 from "../images/productos/ejes/123475/d.jpg";
import image34 from "../images/productos/ejes/125495/a.jpg";
import image35 from "../images/productos/ejes/125495/b.jpg";
import image36 from "../images/productos/ejes/125495/c.jpg";
import image37 from "../images/productos/ejes/125495/d.jpg";
import image38 from "../images/productos/ejes/126632/a.jpg";
import image39 from "../images/productos/ejes/126632/b.jpg";
import image40 from "../images/productos/ejes/126632/c.jpg";
import image41 from "../images/productos/ejes/126632/d.jpg";
import image42 from "../images/productos/ejes/101565/a.jpg";
import image43 from "../images/productos/ejes/101565/b.jpg";
import image44 from "../images/productos/ejes/101565/c.jpg";
import image45 from "../images/productos/ejes/101565/d.jpg";
import image46 from "../images/productos/ejes/139686/a.jpg";
import image47 from "../images/productos/ejes/139686/b.jpg";
import image48 from "../images/productos/ejes/139686/c.jpg";
import image49 from "../images/productos/ejes/139686/d.jpg";
import image50 from "../images/productos/ejes/0750117635/a.jpg";
import image51 from "../images/productos/ejes/0750117635/b.jpg";
import image52 from "../images/productos/ejes/0750117635/c.jpg";
import image53 from "../images/productos/ejes/0750117635/d.jpg";
import image54 from "../images/productos/ejes/138560/a.png";
import image55 from "../images/productos/ejes/138560/b.png";
import image56 from "../images/productos/ejes/95352199/a.png";
import image57 from "../images/productos/ejes/95352199/b.png";
import image58 from "../images/productos/ejes/4472353195/a.png";
import image59 from "../images/productos/ejes/4472353195/b.png";

const ProductGrid = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulación de carga de la página
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
    // Cambia este tiempo según tus necesidades
  }, []);
  const [productsWithDefaultImage] = useState([
    {
      id: 1,
      image: [image, image1, image2, image3],
      title: "Articulación Completa (CAR)",
      description:
        "Eje delantero - Marca Deutz - Modelos: 6 150 / 6 125 / 6 175",
      code: "144489",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 2,
      image: [image4, image5, image6, image7],
      title: "Piñon y corona (CAR)",
      description:
        "Eje delantero - Marca Pauny - Modelos: EVO 230A / 250A / 280A ",
      code: "66294",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 3,
      image: [image8, image9, image10, image11],
      title: "Buje (CAR)",
      description:
        "Eje delantero - Marca Pauny - Modelos: EVO 230A / 250A / 280A",
      code: "101565",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 4,
      image: [image12, image13, image14, image15],
      title: "Engranaje Z34 (ZF)",
      description:
        "Marca Case - Modelos: 7630-7830-8030  Equivalencia MF: 3176340 ",
      code: "95353232",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 5,
      image: [image16, image17, image18, image19],
      title: "Reten carcaza (ZF)",
      description:
        "Tren delantero - Marca Massey Ferguson Modelos: 297-299-630-640",
      code: "734309422",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 6,
      image: [image20, image21, image22],
      title: "Embrague multidisco (CAR)",
      description:
        "Transmisión - Marca Pauny - Modelos: EVO 230A / 250A / 280A",
      code: "570618",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 7,
      image: [image23, image24, image25, image26, image27],
      title: "Eje solar (ZF)",
      description: "Marca Deutz - Modelos: AX 4.150 Equivalencia MF: 3176336",
      code: "4472353157",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 8,
      image: [image28, image28],
      title: "Articulación doble (ZF)",
      description:
        "Marca Deutz - Modelos: AX 4.150 Equivalencia MF: 6285917M91",
      code: "0501398915",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 9,
      image: [image30, image31, image32, image33],
      title: "Buje (CAR)",
      description: "Eje delantero - Marca Fiat/New Holland",
      code: "123475",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 10,
      image: [image34, image35, image36, image37],
      title: "Buje (CAR)",
      description:
        "Eje delantero - Marca Massey ferguson Modelos: 4.297 - 4.299 ",
      code: "125495",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 11,
      image: [image38, image39, image40, image41],
      title: "Buje 115 x 125 x 42 (CAR)",
      description:
        "Eje delantero - Marca Pauny Modelos EVO 230A / 250A / 280A ",
      code: "126632",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 12,
      image: [image42, image43, image44, image45],
      title: "Buje (CAR)",
      description: "Eje delantero - Marca Pauny Modelos EVO 230A / 250A / 280A",
      code: "101565",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 13,
      image: [image46, image47, image48, image49],
      title: "Buje (CAR)",
      description: "Eje delantero - Marca Pauny Modelos EVO 230A / 250A / 280A",
      code: "101565",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 14,
      image: [image50, image51, image52, image53],
      title: "Rodamiento carcaza (ZF)",
      description:
        "Marca Massey Ferguson - Modelos 297-299-630-640 / Equivalencia: 0750117052 ",
      code: "0750117635",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 15,
      image: [image54, image55],
      title: "Buje (ZF)",
      description: "Eje delantero - Buje carraro 114,84 x 1125 x 57 ",
      code: "138560",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 16,
      image: [image56, image57],
      title: "Eje solar Z18 (ZF)",
      description:
        "Marca Massey Ferguson - Eje Z18 6203949M1 / Equivalencia: 84104300 ",
      code: "95352199",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 14,
      image: [image58, image59],
      title: "Pivote (ZF)",
      description:
        "Eje delantero - Marca Pauny - Modelos 230 A / Equivalencia: 3176321 ",
      code: "4472353195",
      link: "https://wa.me/5493412170349",
    },

    // Agrega más productos según sea necesario
  ]);

  return (
    <div>
       {isLoading ? (
        <Loader /> // Muestra el loader mientras isLoading es true
      ) : (
        <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={baner1}
            alt="Primera imagen"
          />
        </Carousel.Item>

        {/* Agrega más items según sea necesario */}
      </Carousel>
      {/*<div className="search-container-general">
        {/* Puedes agregar un campo de búsqueda aquí si lo necesitas 
        <input type="text" placeholder="Buscar..." />
        <button>Buscar</button>
      </div> */}
      <h2 className="pgh2">Nuestros Productos</h2>
      <a
        href="/productsFiltro"
        rel="noopener noreferrer"
        className="buy-button2"
      >
        Volver
      </a>
      <div className="product-general-generalcontainer">
        {productsWithDefaultImage.map((product) => (
          <Product
            key={product.id}
            image={product.image}
            title={product.title}
            description={product.description}
            code={product.code}
            link={product.link}
          />
        ))}
      </div>
      <div className="body-home">
        {/* ... (resto del código) */}
        {/* Agrega el botón de WhatsApp al final */}
        <WhatsAppButton />
      </div>
      </>
      )}
    </div>
  );
};

export default ProductGrid;
