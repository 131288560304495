import React from "react";
import "./loader.css"; // Archivo CSS para estilos del loader
import image from "../images/2.png"
const Loader = () => {
  return (
    <div className="loader-container">
      <img
        src={image}
        alt="Loader"
        className="loader-image"
      />
    </div>
  );
};

export default Loader;
