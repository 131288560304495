import React, { useState } from "react";
import axios from "axios";

const AdminPanel = () => {
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [pdf, setPdf] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!category || !subcategory || !pdf) {
      setErrorMessage("Todos los campos son obligatorios.");
      return;
    }

    const formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("category", category);
    formData.append("subcategory", subcategory);

    try {
      const response = await axios.post(
        "https://api.tractoagro.com.ar/api/upload-pdf",
        formData
      );
      setSuccessMessage("Archivo subido con éxito");
      setErrorMessage(""); // Limpia el mensaje de error
    } catch (error) {
      if (error.response && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          "Error al subir el archivo. Por favor, inténtalo de nuevo."
        );
      }
      setSuccessMessage(""); // Limpia el mensaje de éxito
    }
  };

  return (
    <div className="admin-panel-container">
      <form onSubmit={handleSubmit} className="admin-panel-form">
        <h2>Subir Archivo PDF</h2>
        <div className="form-group">
          <label htmlFor="category">Categoría:</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Selecciona una categoría</option>
            <option value="Carraro">Carraro</option>
            <option value="ZF">ZF</option>
            <option value="General">General</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="subcategory">Subcategoría:</label>
          <select
            id="subcategory"
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
            required
          >
            <option value="">Selecciona una subcategoría</option>
            {category === "Carraro" && (
              <>
                <option value="Lista Carraro Case">Lista Carraro Case</option>
                <option value="Lista Carraro Agco">Lista Carraro Agco</option>
                <option value="Lista Carraro Agrale">
                  Lista Carraro Agrale
                </option>
                <option value="Lista Carraro John Deere">
                  Lista Carraro John Deere
                </option>
                <option value="Lista Carraro New Holland">
                  Lista Carraro New Holland
                </option>
                <option value="Lista Carraro Massey Ferguson">
                  Lista Carraro Massey Ferguson
                </option>
                <option value="Lista Carraro Deutz">Lista Carraro Deutz</option>
                <option value="Lista Carraro Pauny">Lista Carraro Pauny</option>
              </>
            )}
            {category === "ZF" && (
              <>
                <option value="Lista ZF Case">Lista ZF Case</option>
                <option value="Lista ZF Agco">Lista ZF Agco</option>
                <option value="Lista ZF Agrale">Lista ZF Agrale</option>
                <option value="Lista ZF John Deere">Lista ZF John Deere</option>
                <option value="Lista ZF New Holland">
                  Lista ZF New Holland
                </option>
                <option value="Lista ZF Massey Ferguson">
                  Lista ZF Massey Ferguson
                </option>
                <option value="Lista ZF Deutz">Lista ZF Deutz</option>
                <option value="Lista ZF Pauny">Lista ZF Pauny</option>
              </>
            )}
            {category === "General" && (
              <>
                <option value="Lista General Case">Lista General Case</option>
                <option value="Lista General Agco">Lista General Agco</option>
                <option value="Lista General Agrale">
                  Lista General Agrale
                </option>
                <option value="Lista General John Deere">
                  Lista General John Deere
                </option>
                <option value="Lista General New Holland">
                  Lista General New Holland
                </option>
                <option value="Lista General Massey Ferguson">
                  Lista General Massey Ferguson
                </option>
                <option value="Lista General Deutz">Lista General Deutz</option>
                <option value="Lista General Pauny">Lista General Pauny</option>
              </>
            )}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="pdf">Selecciona el archivo PDF:</label>
          <input
            type="file"
            id="pdf"
            accept=".pdf"
            onChange={(e) => setPdf(e.target.files[0])}
            required
          />
        </div>
        <button type="submit">Subir PDF</button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
      </form>
    </div>
  );
};

export default AdminPanel;
