// ProductCard.jsx
import React from "react";
import { Link } from "react-router-dom";


const ProductCard = ({ image, title, description, code, link, brandLogo }) => {
  return (
    <div className="product-card">
      {brandLogo && (
        <div className="brand-logo">
          <img src={brandLogo} alt="Brand Logo" />
        </div>
      )}
      <img src={image} alt={title} />
      <div className="card-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <p className="product-code">Código: {code}</p>
        <Link to={link} className="more-info-button">
          Más Info
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;