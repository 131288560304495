import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./forms.css";

const Register = () => {
  const [companyName, setCompanyName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordValidationMessages, setPasswordValidationMessages] = useState(
    []
  );
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!companyName.trim()) newErrors.companyName = "Este campo es requerido";
    if (!fullName.trim()) newErrors.fullName = "Este campo es requerido";
    if (!phone.trim()) newErrors.phone = "Este campo es requerido";
    if (!email.trim()) newErrors.email = "Este campo es requerido";
    if (!password.trim()) newErrors.password = "Este campo es requerido";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        "https://api.tractoagro.com.ar/api/register",
        {
          company_name: companyName,
          full_name: fullName,
          phone,
          email,
          password,
        }
      );

      setSuccessMessage("Usuario creado con éxito!");
      setErrorMessage(""); // Limpiar mensaje de error si el éxito ocurre
      setCompanyName("");
      setFullName("");
      setPhone("");
      setEmail("");
      setPassword("");
      setErrors({});

      setTimeout(() => {
        navigate("/login");
      }, 4000); // 4 segundos de retraso
    } catch (error) {
      console.error("Error during registration", error);
      // Verificar si hay un mensaje de error personalizado desde el backend
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Error al crear el usuario. Inténtalo nuevamente.");
      }
      setSuccessMessage(""); // Limpiar mensaje de éxito si ocurre un error
    }
  };

  const validatePassword = (password) => {
    const messages = [];
    if (!/[A-Z]/.test(password)) {
      messages.push(
        "La contraseña debe contener al menos una letra mayúscula."
      );
    }
    if (password.length < 8) {
      messages.push("La contraseña debe tener al menos 8 caracteres.");
    }
    return messages;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const validationMessages = validatePassword(newPassword);
    setPasswordValidationMessages(validationMessages);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Permite solo números y actualiza el estado del teléfono
    if (/^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="form-title">Registrate</h2>
        <div className="form-group">
          <input
            type="text"
            placeholder="Nombre de Empresa"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            className={errors.companyName ? "error" : ""}
          />
          {errors.companyName && (
            <span className="error-message">{errors.companyName}</span>
          )}
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Nombre Completo"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            className={errors.fullName ? "error" : ""}
          />
          {errors.fullName && (
            <span className="error-message">{errors.fullName}</span>
          )}
        </div>
        <div className="form-group">
          <input
            type="tel"
            placeholder="Teléfono de Contacto"
            value={phone}
            onChange={handlePhoneChange}
            required
            pattern="[0-9]*"
            className={errors.phone ? "error" : ""}
          />
          {errors.phone && (
            <span className="error-message">{errors.phone}</span>
          )}
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={errors.email ? "error" : ""}
          />
          {errors.email && (
            <span className="error-message">{errors.email}</span>
          )}
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={handlePasswordChange}
            required
            className={errors.password ? "error" : ""}
          />
          {errors.password && (
            <span className="error-message">{errors.password}</span>
          )}
          {passwordValidationMessages.length > 0 && (
            <ul className="validation-list">
              {passwordValidationMessages.map((message, index) => (
                <li key={index} className="validation-message">
                  {message}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button type="submit" className="form-button">
          Registrar
        </button>
      </form>
      {successMessage && (
        <div className="popup-container">
          <div className="popup success-popup">{successMessage}</div>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default Register;
