import React, { useState } from "react";
import "./pdflist.css";

// Importar los archivos PDF
import caseCarraroPDF from "./pdfs/carraro/caseSep.pdf";
import agcoCarraroPDF from "./pdfs/carraro/agcoSep.pdf";
import agraleCarraroPDF from "./pdfs/carraro/agraleSep.pdf";
import johnDeereCarraroPDF from "./pdfs/carraro/jdSep.pdf";
import newHollandCarraroPDF from "./pdfs/carraro/nhSep.pdf";
import masseyFergusonCarraroPDF from "./pdfs/carraro/mfSep.pdf";
import deutzCarraroPDF from "./pdfs/carraro/deutzSep.pdf";
import paunyCarraroPDF from "./pdfs/carraro/paunySep.pdf";

import agraleZFPDF from "./pdfs/zf/agraleZF.pdf";
import johnDeereZFPDF from "./pdfs/zf/jdZF.pdf";
import newHollandZFPDF from "./pdfs/zf/nhZF.pdf";
import masseyFergusonZFPDF from "./pdfs/zf/mfZF.pdf";
import valtraZFPDF from "./pdfs/zf/valZF.pdf";

import generalPDF from "./pdfs/carraro/caseSep.pdf";

import bannerVideo from "../images/video1.mp4"; // Importar el video

const PDFList = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const pdfLinks = {
    Carraro: {
      "Lista Carraro Case": caseCarraroPDF,
      "Lista Carraro Agco": agcoCarraroPDF,
      "Lista Carraro Agrale": agraleCarraroPDF,
      "Lista Carraro John Deere": johnDeereCarraroPDF,
      "Lista Carraro New Holland": newHollandCarraroPDF,
      "Lista Carraro Massey Ferguson": masseyFergusonCarraroPDF,
      "Lista Carraro Deutz": deutzCarraroPDF,
      "Lista Carraro Pauny": paunyCarraroPDF,
    },
    ZF: {
      "Lista ZF Agrale": agraleZFPDF,
      "Lista ZF John Deere": johnDeereZFPDF,
      "Lista ZF New Holland": newHollandZFPDF,
      "Lista ZF Massey Ferguson": masseyFergusonZFPDF,
      "Lista ZF Valtra": valtraZFPDF,
    },
    General: {
      "Lista General Case": generalPDF,
      "Lista General Agco": generalPDF,
      "Lista General Agrale": generalPDF,
      "Lista General John Deere": generalPDF,
      "Lista General New Holland": generalPDF,
      "Lista General Massey Ferguson": generalPDF,
      "Lista General Deutz": generalPDF,
      "Lista General Pauny": generalPDF,
    },
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handlePDFDownload = (brand) => {
    const url = pdfLinks[selectedCategory][brand];
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <div className="pdf-list-container">
      <div className="banner-left">
        {/* Banner izquierdo con video */}
        <video src={bannerVideo} autoPlay loop muted />
      </div>

      <div className="pdf-list-content">
        {" "}
        {/* Contenedor central */}
        <h2 className="title">Descargar Listas de Precios Septiembre 2024</h2>
        <div className="categories">
          <button onClick={() => handleCategoryClick("Carraro")}>
            Carraro
          </button>
          <button onClick={() => handleCategoryClick("ZF")}>ZF</button>
          <button onClick={() => handleCategoryClick("General")}>
            General
          </button>
        </div>
        {selectedCategory && (
          <div className="pdf-options">
            {Object.keys(pdfLinks[selectedCategory]).map((brand) => (
              <button
                key={brand}
                onClick={() => handlePDFDownload(brand)}
                className="pdf-download-button"
              >
                {brand}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="banner-right">
        {/* Banner derecho con video */}
        <video src={bannerVideo} autoPlay loop muted />
      </div>
    </div>
  );
};

export default PDFList;
