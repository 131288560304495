/* eslint-disable */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import WeatherComponent from "./WeatherComponent";
import BrandsComponent from "./BrandsComponent";
import WhatsAppButton from "./WhatsAppButton";
import ProductCard from "./ProductCard";
import DolarHoy from "./DolarHoy";
import imagen1 from "../images/carrusel1.jpg";
import imagen2 from "../images/carrusel2.jpg";
import imagen3 from "../images/mapa.jpeg";
import imagen4 from "../images/banerfinal.jpg";
import imagen5 from "../images/banerNUEVO2.jpeg";
import imagen6 from "../images/banerNUEVO3.jpeg";
import product1Image from "../images/productos/ejes/734309422/b.jpg";
import product2Image from "../images/productos/ejes/570618/c.png";
import product3Image from "../images/productos/ejes/66294/b.jpg";
import product4Image from "../images/productos/ejes/101565/b.jpg";
import product5Image from "../images/productos/ejes/95353232/b.jpg";
import brand3 from "../images/productos/marcas/carraro.png";
import brand12 from "../images/productos/marcas/zfcorrecto.png";
import brand7 from "../images/productos/marcas/jd.png";
import marcaProduct1 from "../images/productos/marcas/carraro.png";
import marcaProduct2 from "../images/productos/marcas/zfcorrecto.png";
import marcaProduct3 from "../images/productos/marcas/nh.jpg";
import Loader from "./loader"


import { FaTractor } from "react-icons/fa";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { GrCycle } from "react-icons/gr";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulación de carga de la página
    setTimeout(() => {
      setIsLoading(false);
    }, 800); 
    // Cambia este tiempo según tus necesidades
  }, []);
  

  return (
    <div className="body-home">
      {isLoading ? (
        <Loader /> // Muestra el loader mientras isLoading es true
      ) : (
        <>
          <h1></h1>
          {/* Agrega el carrusel de imágenes */}
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={imagen4}
                alt="Primera imagen"
              />
            </Carousel.Item>

            {/* Agrega más items según sea necesario */}
          </Carousel>
          {/*<div className="separation-space"></div>*/}
          <div className="centered-section">
            <div className="left-box">
              <FaTractor className="icon" />
              <h3>20 Años de Trayectoria</h3>
              <p>En el sector agrícola</p>
            </div>
            <div className="center-box">
              <BiSolidPlaneAlt className="icon" />
              <h3>Envíos a todo el País</h3>
              <p>Con transportes de confianza</p>
            </div>
            <div className="right-box">
              <GrCycle className="icon" />
              <h3>Distribuidores oficiales</h3>
              <p>De productos Carraro, ZF y FPM</p>
            </div>
          </div>
          <div className="home-content">
            <div className="home-content-left">
              <h2>Quiénes somos</h2>
              <p>
                Somos una empresa dedicada a la fabricación y comercialización
                de repuestos de tractores y cosechadoras. Desde nuestra
                fundación, nos hemos dedicado a elevar constantemente nuestro
                estándar de servicio al cliente, brindando productos de primera
                calidad. Estamos comprometidos con atender las necesidades de
                nuestros clientes en todo el país, trabajando incansablemente
                para garantizar su satisfacción en cada punto de contacto.
              </p>
              <Link to="/nosotros" className="conocenos-button">
                Conócenos
              </Link>
            </div>

            <div className="home-content-right">
              <img src={imagen3} alt="Imagen Descriptiva" />
            </div>
          </div>
          <div className="centered-oficial">
            <div className="left-box-oficial">
              <img src={brand3} alt="Marca 1" />
            </div>
            <div className="center-box-oficial">
              <img src={brand12} alt="Marca 1" />
            </div>
            {/*<div className="right-box-oficial">
          <img src={brand7} alt="Marca 1" />
        </div>*/}
          </div>
          <section className="highlighted-products">
            <h2>Productos Destacados</h2>
            <Carousel>
              <Carousel.Item>
                <div className="product-cards">
                  <ProductCard
                    image={product1Image}
                    title="Reten Carcaza"
                    description="Marcas / Aplicaciones: Massey Ferguson Modelos: 297 - 299 - 630 - 640"
                    code="734309422"
                    link="/products/Product1"
                    brandLogo={marcaProduct2}
                  />
                  <ProductCard
                    image={product2Image}
                    title="Embrague Multidisco"
                    description="Marcas / Aplicaciones: Pauny EVO 230A - 250A - 280A"
                    code="570618"
                    link="/products/Product2"
                    brandLogo={marcaProduct1}
                  />
                  <ProductCard
                    image={product3Image}
                    title="Piñon y Corona"
                    description="Marcas / Aplicaciones: Pauny EVO 230A - 250A - 280A"
                    code="66294"
                    link="/products/Product3"
                    brandLogo={marcaProduct1}
                  />
                  <ProductCard
                    image={product4Image}
                    title="Buje"
                    description="Marcas / Aplicaciones: Fiat-NH RETROEXCAVADORA B90B "
                    code="101565"
                    link="/products/Product4"
                    brandLogo={marcaProduct1}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="product-cards">
                  <ProductCard
                    image={product4Image}
                    title="Buje"
                    description="Marcas / Aplicaciones: Fiat-NH RETROEXCAVADORA B90B "
                    code="101565"
                    link="/products/Product4"
                    brandLogo={marcaProduct1}
                  />
                  <ProductCard
                    image={product5Image}
                    title="Engranaje Z34"
                    description="Marcas / Aplicaciones: Pauny Modelo: 230A / Case Modelos: 7630 - 7830 - 8030 "
                    code="95353232"
                    link="/products/Product5"
                    brandLogo={marcaProduct2}
                  />
                  <ProductCard
                    image={product1Image}
                    title="Reten Carcaza"
                    description="Marcas / Aplicaciones: Massey Ferguson Modelos: 297 - 299 - 630 - 640"
                    code="734309422"
                    link="/products/Product1"
                    brandLogo={marcaProduct2}
                  />
                  <ProductCard
                    image={product2Image}
                    title="Embrague Multidisco"
                    description="Marcas / Aplicaciones: Pauny EVO 230A - 250A - 280A"
                    code="570618"
                    link="/products/Product2"
                    brandLogo={marcaProduct1}
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </section>{" "}
          {/*
      <section className="weather-and-dolar">
        <div className="weather-and-dolar-container">
          <WeatherComponent />
          <DolarHoy />
        </div>
      </section>
          */}
          <BrandsComponent />
          <div className="body-home">
            {/* ... (resto del código) */}
            {/* Agrega el botón de WhatsApp al final */}
            <WhatsAppButton />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
