/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import WhatsAppButton from "./WhatsAppButton";

const ProductDetails = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `https://api.tractoagro.com.ar/api/products/${productId}`
        );
        const data = await response.json();
        setProductDetails(data.productDetails || {});
      } catch (error) {
        console.error("Error fetching product details", error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  return (
    <div>
      <h2>Detalles del Producto</h2>
      <div>
        <h3>{productDetails.descripcion}</h3>
        <p>Calidad {productDetails.proveedor}</p>
        <p>En stock {productDetails.stock}</p>
        <p>$ {productDetails.precio}</p>
        <div>Cod {productDetails.codigo}</div>
        {/* Agrega más detalles según tu modelo de datos */}
      </div>
      <div className="body-home">
        {/* ... (resto del código) */}
        {/* Agrega el botón de WhatsApp al final */}
        <WhatsAppButton />
      </div>
    </div>
  );
};

export default ProductDetails;
