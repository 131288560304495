import React, { useState, useEffect } from "react";
import Product from "../components/ProductGral";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import imagenbanner from "../images/banGRAL.jpg";
import WhatsAppButton from "./WhatsAppButton";
import Loader from "./loader";
import image from "../images/productos/general/65AH/a.png";
import image1 from "../images/productos/general/65AH/b.png";
import image2 from "../images/productos/general/75AH/a.png";
import image3 from "../images/productos/general/75AH/b.png";
import image4 from "../images/productos/general/75AH/c.png";
import image5 from "../images/productos/general/110AH/a.jpg";
import image6 from "../images/productos/general/110AH/b.jpg";
import image7 from "../images/productos/general/110AH/c.jpg";
import image8 from "../images/productos/general/180AH/a.jpg";
import image9 from "../images/productos/general/180AH/b.jpg";
import image10 from "../images/productos/general/180AH/c.jpg";
import image11 from "../images/productos/general/180AH/d.jpg";
import image12 from "../images/productos/general/20543/a.png";
import image13 from "../images/productos/general/20543/b.png";
import image14 from "../images/productos/general/20543/c.png";
import image15 from "../images/productos/general/61649/a.png";
import image16 from "../images/productos/general/61649/b.png";
import image17 from "../images/productos/general/61649/c.png";
import image18 from "../images/productos/general/4004273/a.jpg";
import image19 from "../images/productos/general/4004273/b.jpg";
import image20 from "../images/productos/general/4004273/c.jpg";
import image21 from "../images/productos/general/4004273/d.jpg";
import image22 from "../images/productos/general/9001588/a.jpg";
import image23 from "../images/productos/general/9001588/b.jpg";
import image24 from "../images/productos/general/9001588/c.jpg";
import image25 from "../images/productos/general/9001588/d.jpg";
import image26 from "../images/productos/general/9001589/a.jpg";
import image27 from "../images/productos/general/9001589/b.jpg";
import image28 from "../images/productos/general/9001589/c.jpg";
import image29 from "../images/productos/general/9001589/d.jpg";
import image30 from "../images/productos/general/9001953/a.jpg";
import image31 from "../images/productos/general/9001953/b.jpg";
import image32 from "../images/productos/general/9001953/c.jpg";
import image33 from "../images/productos/general/9002973/a.jpg";
import image34 from "../images/productos/general/9002973/b.jpg";
import image35 from "../images/productos/general/9002973/c.jpg";
import image36 from "../images/productos/general/9005523/a.jpg";
import image37 from "../images/productos/general/9005523/b.jpg";
import image38 from "../images/productos/general/9005523/c.jpg";
import image39 from "../images/productos/general/30181500/a.jpg";
import image40 from "../images/productos/general/30181500/b.jpg";
import image41 from "../images/productos/general/30181500/c.jpg";
import image42 from "../images/productos/general/30181500/d.jpg";
import image43 from "../images/productos/general/AL68094/a.png";
import image44 from "../images/productos/general/AL68094/b.png";
import image45 from "../images/productos/general/AL68094/c.png";
import image46 from "../images/productos/general/AL120106/a.jpg";
import image47 from "../images/productos/general/AL120106/b.jpg";
import image48 from "../images/productos/general/AL120106/c.jpg";
import image49 from "../images/productos/general/AR41949/a.jpg";
import image50 from "../images/productos/general/AR41949/b.jpg";
import image51 from "../images/productos/general/AR41949/c.jpg";
import image52 from "../images/productos/general/AR103033/a.jpg";
import image53 from "../images/productos/general/AR103033/b.jpg";
import image54 from "../images/productos/general/AR103033/c.jpg";
import image55 from "../images/productos/general/butacas/butaca1.jpg";
import image56 from "../images/productos/general/butacas/butaca2.jpg";


  
  
const ProductGenerales = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulación de carga de la página
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
    // Cambia este tiempo según tus necesidades
  }, []);
  const [productsWithDefaultImage] = useState([
    {
      id: 1,
      image: [image12, image13, image14],
      title: "Cilindro de freno (BRA)",
      description: "cilindro de freno importado de Brasil",
      code: "20543",
      link: "https://wa.me/5493412170349",
    },

    {
      id: 1,
      image: [image15, image16, image17],
      title: "Bomba de freno (BRA)",
      description: "Bomba de freno importada de Brasil",
      code: "61649",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image18, image19, image20, image21],
      title: "Cilindro de Freno (MTA)",
      description: "Cilindro maestro tractor Deutz",
      code: "4004273",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image22, image23, image24, image25],
      title: "Cilindro de freno (MTA)",
      description: "Cilindro rueda derecha Massey Ferguson",
      code: "9001588",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image26, image27, image28, image29],
      title: "Cilindro de freno (MTA)",
      description: "Cilindro rueda izquierda Massey Ferguson",
      code: "9001589",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image30, image31, image32],
      title: "Cilindro de freno (MTA)",
      description: "Cilindro maestro D/C Massey Ferguson",
      code: "9001953",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image33, image34, image35],
      title: "Bomba Hidráulica (MJM)",
      description: "Bomba Massey Ferguson doble pinstones",
      code: "9002973",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image36, image37, image38],
      title: "Bomba de freno (MTA)",
      description: "Cilindro maestro D/C Massey Ferguson",
      code: "9005523",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image39, image40, image41, image42],
      title: "Cilindro de freno (MTA)",
      description: "Cilindro para tractor Valtra",
      code: "30181500",
      link: "https://wa.me/5493412170349",
    },

    {
      id: 1,
      image: [image43, image44, image45],
      title: "Bomba transmisión (MJM)",
      description: "Bomba de transmisión hidráulica JD",
      code: "AL68094",
      link: "https://wa.me/5493412170349",
    },

    {
      id: 1,
      image: [image46, image47, image48],
      title: "Bomba transmisión (MJM)",
      description: "Bomba de transmisión con agarre JD",
      code: "AL120106",
      link: "https://wa.me/5493412170349",
    },

    {
      id: 1,
      image: [image49, image50, image51],
      title: "Bomba transmisión (MJM)",
      description: "Bomba de transmisión alto volumen JD",
      code: "AR41949",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image52, image53, image54],
      title: "Bomba hidráulica (MJM)",
      description: "Bomba principal John Deree",
      code: "AR103033",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image, image1],
      title: "Bateria 65AH",
      description: "Bateria 12 x 65 / 10 meses de garantía",
      code: "BAT12X65",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image2, image3, image4],
      title: "Bateria 75AH",
      description: "Bateria 12 x 75 / 10 meses de garantía",
      code: "BAT12X75",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image5, image6, image7],
      title: "Bateria 110AH",
      description: "Bateria 12 x 110 / 10 meses de garantía",
      code: "BAT12X110",
      link: "https://wa.me/5493412170349",
    },
    {
      id: 1,
      image: [image8, image9, image10, image11],
      title: "Bateria 180AH",
      description: "Bateria 12 x 180 / 10 meses de garantía",
      code: "BAT12X180",
      link: "https://wa.me/5493412170349",
    },

    // Agrega más productos según sea necesario
  ]);

  return (
    <div>
      {isLoading ? (
        <Loader /> // Muestra el loader mientras isLoading es true
      ) : (
        <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={imagenbanner}
            alt="Primera imagen"
          />
        </Carousel.Item>

        {/* Agrega más items según sea necesario */}
      </Carousel>
      <h2 className="pgh2">Nuestros Productos</h2>
      <a
        href="/productsFiltro"
        rel="noopener noreferrer"
        className="buy-button2"
      >
        Volver
      </a>
      <div className="product-general-generalcontainer">
        {productsWithDefaultImage.map((product) => (
          <Product
            key={product.id}
            image={product.image}
            title={product.title}
            description={product.description}
            code={product.code}
            link={product.link}
          />
        ))}
      </div>
      <div className="body-home">
        {/* ... (resto del código) */}
        {/* Agrega el botón de WhatsApp al final */}
        <WhatsAppButton />
      </div>
      </>
      )}
    </div>
  );
};

export default ProductGenerales;
