// Nosotros.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./Nosotros.css"; // Agrega estilos según tus necesidades
import Loader from "./loader";
import Cesar from "../images/perfil/cesar1.jpg";
import Mela from "../images/perfil/mela.jpeg";
import Joe from "../images/perfil/joe.jpeg";
import Mauri from "../images/perfil/mauri.jpeg";
import Paula from "../images/perfil/paula.jpeg";
import Juan from "../images/perfil/juan.jpeg";
import Lean from "../images/perfil/lean.jpeg";
import imagen3 from "../images/mapa.jpeg";


const Nosotros = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulación de carga de la página
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
    // Cambia este tiempo según tus necesidades
  }, []);
  return (
    <div className="body-nosotros">
      {isLoading ? (
        <Loader /> // Muestra el loader mientras isLoading es true
      ) : (
        <>
          <div className="home-content">
            <div className="home-content-left">
              <h2>Quiénes somos</h2>
              <p>
                Somos una empresa dedicada a la fabricación y comercialización
                de repuestos de tractores y cosechadoras. Desde nuestra
                fundación, nos hemos dedicado a elevar constantemente nuestro
                estándar de servicio al cliente, brindando productos de primera
                calidad. Estamos comprometidos con atender las necesidades de
                nuestros clientes en todo el país, trabajando incansablemente
                para garantizar su satisfacción en cada punto de contacto.
              </p>
            </div>

            <div className="home-content-right">
              <img src={imagen3} alt="Imagen Descriptiva" />
            </div>
          </div>
          <h2>Nuestro equipo</h2>

          <div className="container">
            <article className="card">
              <div className="background">
                <img src={Juan} alt="profile" />
              </div>
              <div className="content">
                <h2>Juan Gonzalez</h2>
                <p>
                  Planeamiento y Sistemas IT{" "}
                  <a href="https://google.com" title="Google"></a>
                </p>
                <ul className="chips">
                  {/* <li className="chip">React.js</li> */}
                </ul>
                <div className="action-buttons">
                  <a
                    className="secondary"
                    href="https://wa.me/+5493412107359"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactar
                  </a>
                </div>
              </div>
            </article>

            <article className="card">
              <div className="background">
                <img src={Lean} alt="profile" />
              </div>
              <div className="content">
                <h2>Leandro Aramburu</h2>
                <p>
                  Contador y R.R.H.H.{" "}
                  <a href="https://google.com" title="Google"></a>
                </p>
                <ul className="chips">
                  {/* <li className="chip">React.js</li> */}
                </ul>
                <div className="action-buttons">
                  <a
                    className="secondary"
                    href="https://wa.me/+5493415211406"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactar
                  </a>
                </div>
              </div>
            </article>
            <article className="card">
              <div className="background">
                <img src={Mela} alt="profile" />
              </div>
              <div className="content">
                <h2>Melani Isoardi</h2>
                <p>
                  Ventas y Marketing{" "}
                  <a href="https://google.com" title="Google"></a>
                </p>
                <ul className="chips">
                  {/* <li className="chip">React.js</li> */}
                </ul>
                <div className="action-buttons">
                  <a
                    className="secondary"
                    href="https://wa.me/+5493415467700"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactar
                  </a>
                </div>
              </div>
            </article>

            

            <article className="card">
              <div className="background">
                <img src={Mauri} alt="profile" />
              </div>
              <div className="content">
                <h2>Mauricio Dandru</h2>
                <p>
                  Vendedor <a href="https://google.com" title="Google"></a>
                </p>
                <ul className="chips">
                  {/* <li className="chip">React.js</li> */}
                </ul>
                <div className="action-buttons">
                  <a
                    className="secondary"
                    href="https://wa.me/+5493415202400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactar
                  </a>
                </div>
              </div>
            </article>

            <article className="card">
              <div className="background">
                <img src={Paula} alt="profile" />
              </div>
              <div className="content">
                <h2>Paula Sciacca</h2>
                <p>
                  Vendedora <a href="https://google.com" title="Google"></a>
                </p>
                <ul className="chips">
                  {/* <li className="chip">React.js</li> */}
                </ul>
                <div className="action-buttons">
                  <a
                    className="secondary"
                    href="https://wa.me/+5493415080938"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactar{" "}
                  </a>
                </div>
              </div>
            </article>
            <article className="card">
              <div className="background">
                <img src={Cesar} alt="profile" />
              </div>
              <div className="content">
                <h2>Cesar Nebbia</h2>
                <p>
                  CEO Tractoagro{" "}
                  <a href="https://google.com" title="Google"></a>
                </p>
                <ul className="chips">
                  {/* <li className="chip">React.js</li> */}
                </ul>
                <div className="action-buttons">
                  <a
                    className="secondary"
                    href="https://wa.me/+5493415203217"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactar
                  </a>
                </div>
              </div>
            </article>
          </div>
        </>
      )}
    </div>
  );
};

export default Nosotros;
