/* eslint-disable */
import React from "react";
import brand1 from "../images/productos/marcas/agco.png";
import brand2 from "../images/productos/marcas/agrale.jpg";
import brand3 from "../images/productos/marcas/carraro.png";
import brand4 from "../images/productos/marcas/case.png";
import brand5 from "../images/productos/marcas/deutz.jpg";
import brand6 from "../images/productos/marcas/hanomag.png";
import brand7 from "../images/productos/marcas/jd.png";
import brand8 from "../images/productos/marcas/mf.png";
import brand9 from "../images/productos/marcas/nh.jpg";
import brand10 from "../images/productos/marcas/pauny.png";
import brand11 from "../images/productos/marcas/valtra.png";
import brand12 from "../images/productos/marcas/zf.png";
// Importa las demás imágenes de marcas

const BrandsComponent = () => {
  return (
    <div className="brands-container">
      <h2>Marcas que Trabajamos</h2>
      <div className="brands-images">
        <img src={brand1} alt="Marca 1" />
        <img src={brand2} alt="Marca 2" />
        <img src={brand5} alt="Marca 5" />
        <img src={brand6} alt="Marca 6" />
        <img src={brand7} alt="Marca 7" />
        <img src={brand8} alt="Marca 8" />
        <img src={brand9} alt="Marca 9" />
        <img src={brand10} alt="Marca 10" />
        <img src={brand11} alt="Marca 11" />
        {/* Agrega las demás imágenes de marcas */}
      </div>
    </div>
  );
};

export default BrandsComponent;
