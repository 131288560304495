import React, { useState } from "react";
import Product from "../components/ProductFiltro";
import EjeDelantero from "../images/czf3.jpeg";
import General from "../images/rg3.jpeg";
import FPM from "../images/fpm3.jpeg";
import "./ProductFiltro.css";
import { Link } from "react-router-dom";

const ProductFilter = () => {
   const [productsWithDefaultImage] = useState([
     {
       id: 1,
       image: EjeDelantero,
       title: "TREN DELANTERO",
       link: "../Products",
     },
     {
       id: 2,
       image: General,
       title: "REPUESTOS GENERAL",
       link: "../ProductsGenerales", // Cambiado a "./Products"
     },
     {
       id: 2,
       image: FPM,
       title: "EMBRAGUES, DISCOS Y EXTREMOS",
       link: "../ProductsFPM", // Cambiado a "./Products"
     },
   ]);

  return (
    <div>
      <div className="product-filtro-general">
        
        {productsWithDefaultImage.map((product) => (
          <Link to={product.link} key={product.id}>
          <Product
            key={product.id}
            image={product.image}
            title={product.title}
            link={product.link}
          />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductFilter;
