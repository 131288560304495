import React from "react";

const ProductInfoCompleta = ({ product }) => {
  if (!product) {
    // Manejar el caso en que product sea undefined
    return <div>Producto no encontrado</div>;
  }

  return (
    <div>
      <h2>{product.descripcion}</h2>
      <p>Calidad: {product.proveedor}</p>
      <p>En stock: {product.stock}</p>
      <p>Precio: $ {product.precio}</p>
      {/* Agrega más detalles según sea necesario */}
    </div>
  );
};

export default ProductInfoCompleta;
