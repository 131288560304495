// Product1.js
import React from "react";
import "../DetallesProductos.css";
import ImageGallery from "./ImageGallery";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import image1 from "../../images/productos/ejes/570618/a.png";
import image2 from "../../images/productos/ejes/570618/b.png";
import image3 from "../../images/productos/ejes/570618/c.png";
import image6 from "../../images/banerchico.png";

const Product2 = () => {
  const images = [image1, image2, image3,  ]; // Agrega más URLs de imágenes según sea necesario

  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={image6} alt="Primera imagen" />
        </Carousel.Item>

        {/* Agrega más items según sea necesario */}
      </Carousel>
      <a
        href="/"
        rel="noopener noreferrer"
        className="buy-button3"
      >
        Volver
      </a>
      <div className="detalles-container">
        <ImageGallery images={images} />
        <div className="details-content">
          <h2>Embrague Multidisco</h2>
          <h3>Eje Delantero - Carraro</h3>
          <p>USOS / MARCAS: Pauny EVO 230A - 250A - 280A</p>
          <p> </p>
          <p className="product-code">
            <strong>Código:</strong> 570618
          </p>
          <a
            href="https://wa.me/5493412170349?text=Estoy%20interesado%20en%20este%20producto:%20Embrague%20multidisco%20cod:%20570618..."
            target="_blank"
            rel="noopener noreferrer"
            className="buy-button"
          >
            Comprar
          </a>
        </div>
      </div>
    </div>
  );
};

export default Product2;
