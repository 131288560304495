/* eslint-disable */
import React from "react";
import ProductGrid from "./ProductGrid";
import "../../src/Products.css";
import WhatsAppButton from "./WhatsAppButton";


const Products = () => {
  return (
    <div className="products-container-general">
      
      <ProductGrid />
      <div className="body-home">
        {/* ... (resto del código) */}
        {/* Agrega el botón de WhatsApp al final */}
        <WhatsAppButton />
      </div>
    </div>
  );
};

export default Products;