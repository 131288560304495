// Product1.js
import React from "react";
import "../DetallesProductos.css";
import ImageGallery from "./ImageGallery";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import image1 from "../../images/productos/ejes/95353232/a.jpg";
import image2 from "../../images/productos/ejes/95353232/b.jpg";
import image3 from "../../images/productos/ejes/95353232/c.jpg";
import image4 from "../../images/productos/ejes/95353232/d.jpg";
import image6 from "../../images/banerchico.png";

const Product5 = () => {
  const images = [image1, image2, image3, image4]; // Agrega más URLs de imágenes según sea necesario

  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={image6} alt="Primera imagen" />
        </Carousel.Item>

        {/* Agrega más items según sea necesario */}
      </Carousel>
      <a href="/" rel="noopener noreferrer" className="buy-button3">
        Volver
      </a>
      <div className="detalles-container">
        <ImageGallery images={images} />
        <div className="details-content">
          <h2>Engranaje Z34</h2>
          <h3>Caja de transmisión</h3>
          <p>USOS / MARCAS: Pauny 230 A / Modelo de EJES: APL 350</p>
          <p>Equivalencias: Massey Ferguson 95353232</p>
          <p>Valtra 3176340</p> <p>John Deere CQ29408</p>
          <p>New Holland 9579254</p>
          <p>Agrale 8009103304005</p>{" "}
          <p className="product-code">
            <strong>Código:</strong> 95353232
          </p>
          <a
            href="https://wa.me/5493412170349?text=Estoy%20interesado%20en%20este%20producto:%20Engranaje%20Z34%20cod:%2095353232..."
            target="_blank"
            rel="noopener noreferrer"
            className="buy-button"
          >
            Comprar
          </a>
        </div>
      </div>
    </div>
  );
};

export default Product5;
