import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./forms.css";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `https://api.tractoagro.com.ar/api/reset-password/${token}`,
        {
          newPassword,
        }
      );
      setSuccessMessage("Contraseña actualizada correctamente.");
      setErrorMessage(""); // Limpiar mensaje de error si el éxito ocurre
      setTimeout(() => {
        navigate("/login"); // Redirige al login después de un breve retraso
      }, 2000); // 2 segundos de retraso
    } catch (error) {
      console.error("Error updating password", error);
      setErrorMessage("Error al actualizar la contraseña. Intenta nuevamente.");
      setSuccessMessage(""); // Limpiar mensaje de éxito si ocurre un error
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Cambio de Contraseña</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Ingresá tu nueva contraseña"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <button type="submit">Confirmar</button>
      </form>
      {successMessage && (
        <div className="popup-container">
          <div className="popup success-popup">{successMessage}</div>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ResetPassword;
