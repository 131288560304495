import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./styles.css";
import "./productos.css";
import "./Products.css";
import "../src/components/ProductGrid.css";
import "../src/components/ProductFiltro.css";
import "../src/components/Contacto.css";
import "../src/components/forms.css";

import { Helmet } from "react-helmet";
import {
  FaWhatsapp,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";

import Home from "./components/Home";
import Products from "./components/Products";
import ProductsGenerales from "./components/ProductGenerales";
import ProductFPM from "./components/ProductFPM";
import ProductsFiltro from "./components/ProductsFiltro";
import ProductDetail from "./components/ProductDetail";
import ProductosCarraro from "./components/ProductosCarraro";
import ProductosZF from "./components/ProductosZF";
import Nosotros from "./components/Nosotros";
import Contacto from "./components/Contacto";
import logo from "./images/logo2.png";
import ProductInfoCompleta from "./components/ProductInfoCompleta";
import Product1 from "./components/products/Product1";
import Product2 from "./components/products/Product2";
import Product3 from "./components/products/Product3";
import Product4 from "./components/products/Product4";
import Product5 from "./components/products/Product5";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

// Nuevas importaciones
import Register from "./components/Register";
import Login from "./components/Login";
import PDFList from "./components/PDFList";
import PrivateRoute from "./components/PrivateRoute";
import { useAuth } from "./context/AuthContext"; // Importa el hook useAuth
import AdminPanel from "./components/AdminPanel"; // Importa el componente AdminPanel

const App = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { isAuthenticated, logout, user } = useAuth(); // Asegúrate de que el objeto user contenga el rol
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    console.log("App isAuthenticated:", isAuthenticated);
  }, [isAuthenticated]);

  const handleLogout = () => {
    console.log("Logout button clicked");
    logout();
  };

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <Router>
      <div>
        <header>
          <a href="/">
            <img src={logo} alt="Tractoagro Logo" />
          </a>
        </header>
        <nav className="nav-bar">
          <div className="menu-toggle" onClick={toggleMenu}>
            &#9776;
          </div>
          <div className={`menu-options ${showMenu ? "show" : ""}`}>
            <Link to="/" onClick={closeMenu}>
              Inicio
            </Link>
            <Link to="/productsFiltro" onClick={closeMenu}>
              Productos
            </Link>
            <Link to="/productoscarraro" onClick={closeMenu}>
              Productos Carraro y ZF
            </Link>
            <Link to="/nosotros" onClick={closeMenu}>
              Nosotros
            </Link>
            <Link to="/contacto" onClick={closeMenu}>
              Contacto
            </Link>
            {isAuthenticated && (
              <>
                <Link to="/pdf-list" onClick={closeMenu}>
                  Lista de Precios
                </Link>
                {user?.role === "admin" && (
                  <Link to="/admin" onClick={closeMenu}>
                    Panel de Admin
                  </Link>
                )}
                <button onClick={handleLogout} className="logout-button">
                  Cerrar sesión
                </button>
              </>
            )}
            {!isAuthenticated && (
              <>
                <Link to="/register" onClick={closeMenu}>
                  Registro
                </Link>
                <Link to="/login" onClick={closeMenu}>
                  Login
                </Link>
              </>
            )}
          </div>
        </nav>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/productsFiltro" element={<ProductsFiltro />} />
            <Route path="/products" element={<Products />} />
            <Route path="/productsGenerales" element={<ProductsGenerales />} />
            <Route path="/productsfpm" element={<ProductFPM />} />
            <Route path="/products/:id" element={<ProductDetail />} />
            <Route path="/productoscarraro" element={<ProductosCarraro />} />
            <Route path="/productoszf" element={<ProductosZF />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route
              path="/productos/info-completa/:productId"
              element={<ProductInfoCompleta />}
            />
            <Route path="/products/Product1" element={<Product1 />} />
            <Route path="/products/Product2" element={<Product2 />} />
            <Route path="/products/Product3" element={<Product3 />} />
            <Route path="/products/Product4" element={<Product4 />} />
            <Route path="/products/Product5" element={<Product5 />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/pdf-list"
              element={
                <PrivateRoute>
                  <PDFList />
                </PrivateRoute>
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            {/* Ruta para AdminPanel protegida */}
            <Route
              path="/admin"
              element={
                <PrivateRoute requiredRole="admin">
                  <AdminPanel />
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
        <footer>
          <div className="footer-icons"></div>
          <div className="footer-columns">{/* Footer content aquí */}</div>
          <p className="derechos-footer">
            © 2024 Tractoagro. Todos los derechos reservados.
          </p>
          <a
            href="https://wa.me/+5493413365206"
            target="_blank"
            rel="noopener noreferrer"
          >
            Desarrollado por Triangle Abstract Technology
          </a>
        </footer>
      </div>
    </Router>
  );
};

export default App;
